// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  background-color: whitesmoke;
  font-family: 'PT Serif', serif;
}
#as_container { 
  max-width: 10px;
  }
  
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: whitesmoke;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 800px) {
  html, body {
    overflow-x: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,8BAA8B;AAChC;AACA;EACE,eAAe;EACf;;AAEF;EACE,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,4BAA4B;EAC5B;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;aACW;AACb","sourcesContent":["#root {\n  background-color: whitesmoke;\n  font-family: 'PT Serif', serif;\n}\n#as_container { \n  max-width: 10px;\n  }\n  \nbody {\n  margin: 0;\n  padding: 0;\n  overflow-x: hidden;\n  background-color: whitesmoke;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n@media (max-width: 800px) {\n  html, body {\n    overflow-x: hidden;\n  }\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

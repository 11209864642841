import React from "react";
import { CardColumns, CardGroup } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHospital, faClinicMedical, faCar } from '@fortawesome/free-solid-svg-icons';

const Section = styled.section`
  width: 100%;
  padding: 24px;
  box-sizing: border-box;
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;

  @media (min-width: 768px) {
    justify-content: space-evenly;
  }

  @media (min-width: 1024px) {
    justify-content: space-evenly;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border:none;
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

const Subheading = styled.p`
  font-size: 1.125rem;
  color: #666666;
  max-width: 100%;

  @media (max-width: 767px) {
    text-align: center;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ServicesSection = () => {
  return (
    <Section>
      <Container>
        <TitleContainer>
          <Heading>Our Services</Heading>
          <Subheading>
            We offer a wide range of NEMT services to cater to the unique needs of our clients.
          </Subheading>
        </TitleContainer>
        <CardGroup>
          <Card>
          <FontAwesomeIcon icon={faCar} className="h-16 w-16" style={{ fontSize: '2.5em' }} />
            <h3 className="text-2xl font-semibold">Medical Appointments</h3>

          </Card>
          <Card>
            <FontAwesomeIcon icon={faHospital} className="h-16 w-16" style={{ fontSize: '2.5em' }} />
            <h3 className="text-2xl font-semibold">Hospital Discharge</h3>
            
          </Card>
  
          <Card>
            <FontAwesomeIcon icon={faHospital} className="h-16 w-16" style={{ fontSize: '2.5em' }} />
            <h3 className="text-2xl font-semibold">Dialysis Treatment</h3>
          </Card>
          <Card>
            <FontAwesomeIcon icon={faClinicMedical} className="h-16 w-16" style={{ fontSize: '2.5em' }} />
            <h3 className="text-2xl font-semibold">Physical Therapy</h3>
          </Card>
        </CardGroup>
      </Container>
    </Section>
  );
};


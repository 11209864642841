import React,{useState,useEffect} from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import heroImage from '../../assets/car.jpg';
import { useNavigate } from 'react-router-dom';
import {BreakingPoint} from '../breakingPoint';
import { SignUpModal } from '../signupModal';
import { SignInModal } from '../signInModal';
const HeroSectionContainer = styled.div`
  position: relative;
  height: 100vh;
  background: url(${heroImage}) center/cover;
  display: initial;
  flex-direction: column;
  justify-content: flex-end;

`;



const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 1rem;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledButton = styled(Button)`
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const MobileButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
 // padding: 1rem;
  position: ${(props) => (props.fixed ? 'fixed' : 'static')};
  bottom: 0;
  //background-color: #000;
  z-index: 100;
  border-radius: 0.25rem;
`;
const MobileButton = styled(Button)`
  font-size: 1rem;
  margin: 0 0.5rem;
  height: 40px;
`;


export const HeroSection = () => {
  const navigate = useNavigate();
  const{isMobile}=BreakingPoint();
  const [scrolling, setScrolling] = useState(false);

  const handleScroll = () => {
    setScrolling(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };


  const openSignInModal = () => {
    setIsSignInModalOpen(true);
  };

  const closeSignInModal = () => {
    setIsSignInModalOpen(false);
  };


  const goToRidePage = () => {
    console.log('Button clicked');
    navigate('/booking');
  };

  return (
    <HeroSectionContainer>
      
      <ContentContainer>
        <div className="text-center mb-8">
          <Title>Reliable Non-Emergency Medical Transportation</Title>
          <Subtitle>Your trusted partner in safe and comfortable journeys.</Subtitle>
          <Subtitle>Now with an option for private pay</Subtitle>
          <StyledButton color="danger" onClick={goToRidePage}>
            Book Ride
          </StyledButton>
          <SignUpModal isOpen={isSignUpModalOpen} onClose={closeSignUpModal} />
        <SignInModal isOpen={isSignInModalOpen} onClose={closeSignInModal} />
        </div>
       
        {isMobile ? (
  <>
    
    <MobileButtonsContainer fixed={scrolling}>
      <MobileButton onClick={openSignInModal}  color="danger" className="text-white rounded-full mx-2">
        Sign in
      </MobileButton>
      <MobileButton onClick={openSignUpModal} color="danger" className="text-white rounded-full mx-2">
        Sign up
      </MobileButton>
      <MobileButton onClick={()=>navigate('/privatePay')} color="danger" className="text-white rounded-full mx-2">
        Book Private Pay
      </MobileButton>
    </MobileButtonsContainer>
  </>
) : (
 <></>
)}
     </ContentContainer>
    </HeroSectionContainer>
  );
};

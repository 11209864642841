import React from 'react';
import styled from 'styled-components';
import {Navbar} from '../components/navbar';
import logoImg from '../assets/logo.svg';
import { Footer } from '../components/footer';
import { TitleBar } from '../components/titleBar';
import { PaymentStatus } from '../components/paymentStatus';
import { Marginer } from '../components/marginer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;



export const PaymentStatusPage = () => {
    return (
      <Container>
        <Navbar />
        <PaymentStatus/>
        <Marginer margin="15em" direction="vertical"/>
        <Footer/>
      </Container>
    );
  };
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import styled, { createGlobalStyle }  from 'styled-components';
import { Form, FormGroup, FormFeedback, Label, Input, Spinner, Button, Modal, ModalHeader, ModalBody, } from 'reactstrap';
import { constants } from '../../common';
import axios from 'axios';
import { SignUpModal } from '../signupModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { CheckoutModal } from '../checkoutForm';
import useFormDataStore from '../../store/store';

const stripePromise = loadStripe('pk_live_51OKbVLDeo6BpLoWJI3g9ndIZUiEW5L8AyMhkvb6Xg8oWotaNwPM25Bp3AznIputUZqLcbDIN2W44I9hYVdYtZD7s004n4YyiiY');



const StyledForm = styled(Form)`
  background-color: #fff;
  padding: 3rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: auto;
  margin: 15px;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;

const AutosuggestContainer = styled.div`
  box-sizing: border-box;
  width: 100%; // Ensures the container fits its parent's width
  overflow-x: hidden;
  // Media query for devices with a width of up to 600px (adjust as needed)
  @media (max-width: 600px) {
    padding: 0 10px; // Adjust padding for small screens
    margin: 0 auto; // Center the container on small screens
    
    
  }
`;

// Styled input with responsive and box-sizing properties
const StyledInput = styled(Input)`
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box; // Include padding and border in the element's total width and height
  background-color: rgba(102, 102, 102, 0.15);

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  @media (max-width: 600px) {
    // Adjustments for mobile devices
  }
`;



const StyledModal = styled(Modal)`
  // Your custom styles for the modal container
`;

const ModalContent = styled.div`
  // Your custom styles for the modal content
`;

const ButtonContainer = styled.div`
  // Your custom styles for the button container
`;

const GlobalSuggestionBoxStyles = createGlobalStyle`
  /* Add your custom styles for the suggestion box */
  #as_containerSearch_pickupBox {
    max-width:300px;}

  #as_containerSearch_dropoffBox{
    max-width:300px;
  }
     
`;


const StyledButton = styled(Button)`
  color: white;
  background-color: ${(props) => props.backgroundColor}; /* Set the background color based on the prop or default to red */
  padding: 10px;
  margin:10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;
export const RideForm = () => {
  const { handleSubmit, control, setValue, formState: { errors } } = useForm();
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const apiKey = "Al9_kU2nplzlfDhu4ybdM1JbGprXCkxaYRyQzeUvrqlrvNlY1Y66K3ZIQT2fAp_B";
  const [showModal, setShowModal] = useState(false);
  const [distance, setDistance] = useState(null);
  const [estimatedRideFare, setEstimatedRideFare] = useState(null);
  const [rideType, setRideType] = useState(null);
  const [isRoundTrip, setIsRoundTrip] = useState(null);
  const [pickupData, setPickupData] = useState('');
  const [dropoffData, setdropoffData] = useState('');
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { formData, setFormData } = useFormDataStore();
  const [loading, setLoading] = useState(false);
  const [isSuspended, setIsSuspended] = useState();
  const [signup, setsignUp] = useState(false);

  useEffect(() => {
    openSignUpModal();
  }, [])


  useEffect(() => {
    const fetchSuspensionStatus = async () => {
      try {
        const response = await axios.get(`${constants.baseUrl}rides/bookstatus`);
        if (response.status === 200) {
          setIsSuspended(response.data);
        }
      } catch (error) {
        console.error('Error fetching suspension status:', error);
      }
    };

    fetchSuspensionStatus();
  }, [showModal]); // Empty dependency array to run the effect only once on mount



  const handleShowPaymentModal = () => {
    setShowModal(false);

    // Open the Payment modal once the Ride Details modal is closed
    setShowPaymentModal(true);
  };

  useEffect(() => {
    const loadMapScenario = () => {
      window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', () => {
        const options = {
          maxResults: 4,
        };

        const manager = new window.Microsoft.Maps.AutosuggestManager(options);
        manager.attachAutosuggest('#pickupBox', '#pickupBoxContainer', (suggestionResult) =>
          handleSuggestion(suggestionResult, 'pickupLocation', setPickupSuggestions)
        );




      });

      window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', () => {
        const options = {
          maxResults: 4,
        };

        const manager = new window.Microsoft.Maps.AutosuggestManager(options);
        manager.attachAutosuggest('#dropoffBox', '#dropoffBoxContainer', (suggestionResult) =>
          handleSuggestion(suggestionResult, 'dropoffLocation', setPickupSuggestions)
        );




      });

      


      const handleSuggestion = (suggestionResult, fieldName, setSuggestions) => {
        // Set the value in the form field
        setValue(fieldName, suggestionResult.formattedSuggestion);


        // Update the suggestions state
        setSuggestions(suggestionResult.suggestions);
      };
    };

    if (!window.Microsoft) {
      // Load Bing Maps API
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.bing.com/api/maps/mapcontrol?key=${apiKey}&callback=loadMapScenario`;
      document.body.appendChild(script);

      // Define the callback function in the global scope
      window.loadMapScenario = loadMapScenario;
    } else {
      // Bing Maps API is already loaded, directly call loadMapScenario
      loadMapScenario();
    }
  }, []);


  useEffect(() => {
    const adjustDropdown = () => {
      // Target the Bing Maps Autosuggest dropdown
      const dropdowns = document.querySelectorAll('.Autosuggest-dropdown'); // Update selector as necessary
      dropdowns.forEach(dropdown => {
        dropdown.style.maxWidth = `${window.innerWidth - 20}px`; // Adjust as necessary
      });
    };
  
    // You might need to find a specific event from Bing Maps to hook this adjustment onto,
    // such as after the autosuggest suggestions are updated.
  }, []);

  const handleBookWithAgent = () => {
    const phoneNumber = '719-785-1717';
    const telLink = `tel:${phoneNumber}`;
    window.location.href = telLink;
    setShowModal(false);
  };

  const openSignUpModal = () => {
    setsignUp(true);
  };

  const closeSignUpModal = () => {
    setsignUp(false);
  };



  const handleCloseModal = () => {
    setShowModal(false);

  };

  const onSubmit = async (data) => {
    setLoading(true);
    setFormData(data);

    setPickupData(data.pickupLocation);
    setdropoffData(data.dropoffLocation);
    setIsRoundTrip(data.roundTrip);
    setRideType(data.rideType);

    try {
      const response = await axios.get(`${constants.baseUrl}rides/calculate-distance`, {
        params: {
          origin: data.pickupLocation,
          destination: data.dropoffLocation,
          type:data.rideType,
          isRoundTrip:data.roundTrip
        },
      });
      if (response.status === 200) { setLoading(false); }
      const resp = response.data;


      // Set the distance and estimated ride fare
      setDistance(resp.distance);
      setEstimatedRideFare(Number(resp.estimatedRideFare.toFixed(2)));

      // Show the ride details modal
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching distance:', error.message);
      setLoading(false);
    }
  };

  return (
    <>
    <GlobalSuggestionBoxStyles/>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="patientFirstName">Patient's First Name</Label>
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{ required: 'Patient\'s First Name is required' }}
            render={({ field }) => (
              <>
                <StyledInput
                  {...field}
                  type="text"
                  id="patientFirstName"
                  placeholder="Enter patient's first name"
                  invalid={!!errors.patientFirstName}
                />
                <ErrorText>{errors.patientFirstName?.message}</ErrorText>
              </>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="patientLastName">Patient's Last Name</Label>
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{ required: 'Patient\'s Last Name is required' }}
            render={({ field }) => (
              <>
                <StyledInput
                  {...field}
                  type="text"
                  id="patientLastName"
                  placeholder="Enter patient's last name"
                  invalid={!!errors.patientLastName}
                />
                <ErrorText>{errors.patientLastName?.message}</ErrorText>
              </>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="pickupLocation">Pickup Location</Label>
          <Controller
            name="pickupLocation"
            control={control}
            defaultValue=""
            rules={{ required: 'Pickup Location is required' }}
            render={({ field }) => (
              <AutosuggestContainer>
                <StyledInput
                  {...field}
                  type="text"
                  id="pickupBox"
                  placeholder="Enter pickup location"
                  invalid={!!errors.pickupLocation}
                />
                <div id="pickupBoxContainer"></div>
                <ErrorText>{errors.pickupLocation?.message}</ErrorText>
              </AutosuggestContainer>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="dropoffLocation">Dropoff Location</Label>
          <Controller
            name="dropoffLocation"
            control={control}
            defaultValue=""
            rules={{ required: 'Dropoff Location is required' }}
            render={({ field }) => (
              <AutosuggestContainer>
                <StyledInput
                  {...field}
                  type="text"
                  id="dropoffBox"
                  placeholder="Enter dropoff location"
                  invalid={!!errors.dropoffLocation}
                />
                <div id="dropoffBoxContainer"></div>
                <ErrorText>{errors.dropoffLocation?.message}</ErrorText>
              </AutosuggestContainer>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="phone">Phone Number</Label>
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            rules={{ required: 'Phone Number is required' }}
            render={({ field }) => (
              <>
                <StyledInput
                  {...field}
                  type="number"
                  id="phone"
                  placeholder="Enter phone number"
                  invalid={!!errors.phone}
                />
                <ErrorText>{errors.phone?.message}</ErrorText>
              </>
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="appointmentDate">Appointment Date</Label>
          <Controller
            name="appointmentDate"
            control={control}
            render={({ field }) => (
              <StyledInput
                {...field}
                type="date"
                id="appointmentDate"
              />
            )}
          />
        </FormGroup>

        <FormGroup>
          <Label for="appointmentTime">Appointment Time</Label>
          <Controller
            name="appointmentTime"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="time"
                id="appointmentTime"
              />
            )}
          />
        </FormGroup>

        <Label for="rideType">Ride Type</Label>
        <FormGroup>
          <Controller
            name="rideType"
            control={control}
            defaultValue="0" // Assuming "0" is a valid default value and it's set as a string
            render={({ field }) => (
              <>
                <div>
                  <Input type="radio" {...field} id="rideTypeWheelchair" value="0" checked={field.value === "0"} />
                  <Label for="rideTypeWheelchair">Wheelchair</Label>
                </div>
                <div>
                  <Input type="radio" {...field} id="rideTypeAmbulatory" value="1" checked={field.value === "1"} />
                  <Label for="rideTypeAmbulatory">Ambulatory</Label>
                </div>
              </>
            )}
          />
        </FormGroup>


        {/* Round Trip Checkbox */}
        <Label for="roundTrip">Round Trip</Label>
        <Controller
          name="roundTrip"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormGroup>
              <Input type="checkbox" id="roundTrip" {...field} checked={field.value} />
              <Label for="roundTrip">Check for Round Trip</Label>
            </FormGroup>
          )}
        />


        <FormGroup>
          <Label for="specificRequirements">Specific Requirements</Label>
          <Controller
            name="specificRequirements"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <StyledInput
                {...field}
                type="textarea"
                id="specificRequirements"
                placeholder="Enter any specific requirements"
              />
            )}
          />
        </FormGroup>

        {loading ? (
          <Spinner color="primary" />
        ) : (
          <Button className="mt-3" style={{ backgroundColor: "red", borderColor: "red" }} type="submit">
            Get an Estimate
          </Button>
        )}

      </StyledForm>
      <SignUpModal isOpen={signup} header={"Sign up to view your trips"} onClose={closeSignUpModal} />
      <div>
        {/* Your main ride details modal */}
        <Modal isOpen={showModal} toggle={handleCloseModal}>
          <ModalHeader toggle={handleCloseModal}>Ride Details</ModalHeader>
          <ModalBody>
            <p>Distance: {distance?.toFixed(2)}</p>
            <p>Estimated Ride Fare: {estimatedRideFare?.toFixed(2)}</p>
            <ButtonContainer>
              <StyledButton className="mt-3" backgroundColor="black" onClick={handleBookWithAgent}>
                <FontAwesomeIcon icon={faUser} /> Book with Agent
              </StyledButton>
              {console.log(isSuspended)}
              {!isSuspended &&

                <StyledButton className="mt-3" style={{ backgroundColor: "red", borderColor: "red" }} onClick={handleShowPaymentModal}>
                  <FontAwesomeIcon icon={faMoneyBillAlt} /> Pay Now
                </StyledButton>
              }
            </ButtonContainer>
          </ModalBody>
        </Modal>

        {/* Your Payment modal */}
        <Elements stripe={stripePromise}>
          <CheckoutModal isOpen={showPaymentModal} toggle={() => setShowPaymentModal(false)} pickup={pickupData} distance={distance} dropoff={dropoffData} amount={estimatedRideFare} handleClose={() => setShowPaymentModal(false)} />
        </Elements>
      </div>
    </>
  );
};

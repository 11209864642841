import React from 'react';
import { Input, Button, FormGroup, Label, FormFeedback, Spinner,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { constants } from '../../common';
import axios from 'axios';


const StyledModal = styled(Modal)`
  & .modal-content {
    background-color: #fff;
    border-radius: 10px;
  }
`;

const FormContainer = styled.form`
  max-width: 400px;
  margin: auto;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;


export const SignUpModal = ({ isOpen, onClose,header }) => {
  const { control, handleSubmit, formState: { errors },watch,reset } = useForm();
  const {baseUrl}=constants;
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  
  const onSubmit = async (data) => {
    setLoading(true);
    const obj = {
      appUser: {
        name: data.name,
        email: data.email,
        password: data.password
      },
      rides: []
    };
  
    try {
      // Make a POST request to the endpoint
      const response = await axios.post(`${baseUrl}customers`, obj);
  
      // Check the response status and handle accordingly
      if (response.status === 201) {
        // Successful response, you can handle the result here
        setLoading(false);
        setResponseMessage('Customer created successfully');
        setTimeout(() => {
          reset(); // Assuming reset is a function from react-hook-form to reset the form
          onClose();
          setResponseMessage(null) // Close the modal after 3 seconds (adjust the time as needed)
        }, 3000);
      } else {
        // Handle other response statuses as needed
        setResponseMessage('Error creating customer. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      // Handle any errors that occurred during the request
      console.error('Error creating customer:', error.message);
      setLoading(false);
      setResponseMessage('An error occurred. Please try again later.');
    }
  };



  return (
    <StyledModal isOpen={isOpen} header={header} toggle={onClose}>
      <ModalHeader toggle={onClose}>{header}</ModalHeader>
      <ModalBody>
      {responseMessage && <p>{responseMessage}</p>}
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Name is required',
                pattern: {
                  value: /^[A-Za-z]+$/,
                  message: 'Name should only contain letters',
                },
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="text"
                    id="name"
                    {...field}
                    invalid={!!errors.name}

                  />
                  <ErrorText>{errors?.name?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="email">Email</Label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="email"
                    id="email"
                    {...field}
                    invalid={!!errors?.email}
                  />
                  <ErrorText>{errors?.email?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="password">Password</Label>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: 'Password is required',
                minLength: {
                  value: 6,
                  message: 'Password must be at least 6 characters long',
                },
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="password"
                    id="password"
                    {...field}
                    invalid={!!errors?.password}
                  />
                  <ErrorText>{errors?.password?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="confirmPassword">Confirm Password</Label>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: 'Confirm Password is required',
                validate: (value) =>
                  value === watch('password') || 'Passwords do not match',
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="password"
                    id="confirmPassword"
                    {...field}
                    invalid={!!errors?.confirmPassword}
                  />
                  <ErrorText>{errors?.confirmPassword?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          {loading ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button style={{ backgroundColor: "red", borderColor: "red" }} type="submit">
                            Sign Up
                        </Button>
                    )}
        </FormContainer>
      </ModalBody>
    </StyledModal>
  );
};
import React, { useState,useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { constants } from '../../common';
import useFormDataStore from '../../store/store';

export const CheckoutModal = ({ isOpen, toggle, amount,pickup,dropoff,distance}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const { formData } = useFormDataStore();
 

  const handleSubmit = async (event) => {
    event.preventDefault();

    const handleSuccessfulPayment = () => {
        alert("Ride booked you will be getting a call from us shortly");
    };
 




    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
      // Make a request to your backend to create a checkout session using axios
      const response = await axios.post(`${constants.baseUrl}api/payment/create-checkout-session?amount=${amount}`);

      const { sessionId } = response.data;
      localStorage.setItem("ssId",sessionId);

      // Use Stripe.js to redirect the customer to the checkout page
      const result = await stripe.redirectToCheckout({
        sessionId: sessionId,
      });
      

      if (result.error) {
        // If `redirectToCheckout` fails, display the localized error message to your customer
        setError(result.error.message);
      }else{

        console.log("Payment successful. Performing additional actions.");
            
            // For example, you can trigger some function or navigate to a different page
            handleSuccessfulPayment();
      }
      
    } catch (error) {
      console.error('Error creating checkout session:', error);
      setError('Error creating checkout session');
    }
  };

 

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Payment Details</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <FormGroup className="mb-4">
              <h4>Pick up Location</h4>
                     <h5>{pickup}</h5>
              <h4>Dropoff location</h4>
                  <h5>{dropoff}</h5>
                  <h4>Ride Distance</h4>
                     <h5>{distance}</h5>
              <h4>Amount</h4>
                  <h5>{amount}</h5>          
            
            <Button type="submit" color="danger" disabled={!stripe}>
              Pay ${amount}
            </Button>
          </FormGroup>
          {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
        </form>
      </ModalBody>
    </Modal>
  );
};

import React, { useState } from 'react';
import { Button, FormGroup, Label, FormFeedback,Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import qs from 'qs';
import { constants } from '../../common';


const StyledModal = styled(Modal)`
  & .modal-content {
    background-color: #fff;
    border-radius: 10px;
  }
`;

const FormContainer = styled.form`
  max-width: 400px;
  margin: auto;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;

export const SignInModal = ({ isOpen, onClose }) => {
    const { control, handleSubmit, formState: { errors }, reset } = useForm();
    const [responseMessage, setResponseMessage] = React.useState(null);
    const [loading, setLoading] = useState(false);

    const onSubmit = (authData) => {
        setLoading(true);
        axios({
            method: 'POST',
            url: `${constants.baseUrl}login`,
            data: qs.stringify({
                username: authData.email,
                password: authData.password,
            }),
            headers: {
                'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    const { access_token } = response.data;
                    localStorage.setItem('token', access_token);
                    setResponseMessage(' You have signed in successfully');
                    setTimeout(() => {
                        reset(); // Assuming reset is a function from react-hook-form to reset the form
                        onClose(); // Close the modal after 3 seconds (adjust the time as needed)
                    }, 3000);
                }
            })
            .catch((err) => setLoading(false), setResponseMessage('Email or Password incorrect.Please try again.'));
    };

    return (
        <StyledModal isOpen={isOpen} toggle={onClose}>
            <ModalHeader toggle={onClose}>Sign In</ModalHeader>
            <ModalBody>
                {responseMessage && <p>{responseMessage}</p>}
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Email is required',
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: 'Invalid email address',
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <StyledInput
                                        type="email"
                                        id="email"
                                        placeholder="Your Email"
                                        {...field}
                                        invalid={!!errors.email} // Add the invalid prop based on the errors
                                    />
                                    <ErrorText>{errors.email?.message}</ErrorText>
                                </>
                            )}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: 'Password is required',
                                minLength: {
                                    value: 6,
                                    message: 'Password must be at least 6 characters long',
                                },
                            }}
                            render={({ field }) => (
                                <>
                                    <StyledInput
                                        type="password"
                                        id="password"
                                        placeholder="Your Password"
                                        {...field}
                                        invalid={!!errors.password} // Add the invalid prop based on the errors
                                    />
                                    <ErrorText>{errors.password?.message}</ErrorText>
                                </>
                            )}
                        />
                    </FormGroup>
                    {loading ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button style={{ backgroundColor: "red", borderColor: "red" }} type="submit">
                            Sign In
                        </Button>
                    )}
                </FormContainer>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </StyledModal>
    );
};

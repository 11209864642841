import React from 'react';
import styled from 'styled-components';
import {Navbar} from '../components/navbar';
import { Marginer } from '../components/marginer';
import { HeroSection } from '../components/topsection';
import { AboutUsSection } from '../components/aboutussection';
import { ServicesSection } from '../components/services';
import { Footer } from '../components/footer';
import { PastWork } from '../components/pastwork';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;



export const HomePage = () => {
  return (
    <Container>
      <Navbar />
      <HeroSection/>
      <AboutUsSection id="about"/>
      <ServicesSection id="services"/>
      <Marginer direction="vertical" margin="4em"/>
      <PastWork/>
      <Marginer direction="vertical" margin="4em"/>
      <Footer/>
    </Container>
  );
};
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Container, Row, Col, Input, Button, Label, FormGroup, FormFeedback, Spinner } from 'reactstrap';
import styled from 'styled-components';
import { Marginer } from '../marginer';
import axios from 'axios';
import { constants } from '../../common';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* White background */
`;

const StyledForm = styled.form`
  background-color: #fff; /* White background */
  padding: 2rem;
  border: 2px dotted red; /* Black border */
  border-radius: 0.25rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin:15px;
`;

const StyledInput = styled(Input)`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #808080; /* Gray border */
  border-radius: 0.25rem;
  background-color:rgba(102,102,102,.15);
`;

const StyledSelect = styled(Input)`
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #808080; /* Gray border */
  border-radius: 0.25rem;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;

const StyledLabel = styled(Label)`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const BookingForm = () => {
  const { handleSubmit, control, setValue, formState: { errors } } = useForm();
  const [pickupSuggestions, setPickupSuggestions] = useState([]);
  const [dropoffSuggestions, setDropoffSuggestions] = useState([]);
  const apiKey = "Al9_kU2nplzlfDhu4ybdM1JbGprXCkxaYRyQzeUvrqlrvNlY1Y66K3ZIQT2fAp_B";
  const navigate = useNavigate();
  const [rideType, setRideType] = useState('A');
  const [isRoundTrip, setIsRoundTrip] = useState(false);
  const [loading, setLoading] = useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);


  useEffect(() => {
    const loadMapScenario = () => {
      window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', () => {
        const options = {
          maxResults: 4,
        };

        const manager = new window.Microsoft.Maps.AutosuggestManager(options);
        manager.attachAutosuggest('#pickupBox', '#pickupBoxContainer', (suggestionResult) =>
          handleSuggestion(suggestionResult, 'pickupLocation', setPickupSuggestions)
        );




      });

      window.Microsoft.Maps.loadModule('Microsoft.Maps.AutoSuggest', () => {
        const options = {
          maxResults: 4,
        };

        const manager = new window.Microsoft.Maps.AutosuggestManager(options);
        manager.attachAutosuggest('#dropoffBox', '#dropoffBoxContainer', (suggestionResult) =>
          handleSuggestion(suggestionResult, 'dropoffLocation', setPickupSuggestions)
        );




      });




      const handleSuggestion = (suggestionResult, fieldName, setSuggestions) => {
        // Set the value in the form field
        setValue(fieldName, suggestionResult.formattedSuggestion);

        // Log the suggestion details to the console or perform additional actions
        console.log(`Suggestion for ${fieldName}: `, suggestionResult);

        // Update the suggestions state
        setSuggestions(suggestionResult.suggestions);
      };
    };

    if (!window.Microsoft) {
      // Load Bing Maps API
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.bing.com/api/maps/mapcontrol?key=${apiKey}&callback=loadMapScenario`;
      document.body.appendChild(script);

      // Define the callback function in the global scope
      window.loadMapScenario = loadMapScenario;
    } else {
      // Bing Maps API is already loaded, directly call loadMapScenario
      loadMapScenario();
    }
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const authToken = localStorage.getItem('token');
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};

      const response = await axios.post(`${constants.baseUrl}bookings`, data, { headers });

      if (response.status === 201) {
        // Show success alert
        setLoading(false);
        alert('Booking created successfully!,you will recieve a call from us shortly');

        // Redirect to the home page after a delay (e.g., 2 seconds)
        setTimeout(() => {
          navigate('/');// Replace '/' with the actual path to your home page
        }, 5000);
      } else {
        setLoading(false);
        console.error('Unexpected response status:', response.status);
        alert('Booking didnt go through please try again');
      }
    } catch (error) {
      setLoading(false);
      console.error('Error saving ride:', error);
      // Handle error
    }
  };

  return (
    <StyledContainer>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <h2 className="text-2xl mb-6 text-red-500 font-bold">Schedule A Ride Online: Trip Information</h2>

        {/* Personal Information */}
        <Row form>
          <Col md={4}>
            <StyledLabel for="firstName">First Name</StyledLabel>
            <Controller
              name="firstName"
              control={control}
              defaultValue=""
              rules={{
                required: 'Name is required',
                pattern: {
                  value: /^[A-Za-z]+$/,
                  message: 'Name should only contain letters',
                },
              }}
              render={({ field }) => (
                <StyledInput
                  type="text"
                  id="firstName"
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <StyledLabel for="lastName">Last Name</StyledLabel>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              rules={{
                required: 'Name is required',
                pattern: {
                  value: /^[A-Za-z]+$/,
                  message: 'Name should only contain letters',
                },
              }}
              render={({ field }) => (
                <StyledInput
                  type="text"
                  id="lastName"
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <StyledLabel for="dateOfBirth">Date of Birth (mm/dd/yyyy)</StyledLabel>
            <Controller
              name="dateOfBirth"
              control={control}
              defaultValue=""
              rules={{ required: 'Date of birth is required' }}
              render={({ field }) => (
                <StyledInput
                  type="date"
                  className="datepicker"
                  id="dateOfBirth"
                  {...field}

                />
              )}
            />
          </Col>
        </Row>

        {/* Gender and Medical Number */}
        <Row form>
          <Col md={4}>
            <StyledLabel for="gender">Gender</StyledLabel>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              rules={{ required: 'Gender is required' }}
              render={({ field }) => (
                <StyledSelect
                  type="select"
                  id="gender"
                  {...field}
                >
                  <option value="" disabled>Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </StyledSelect>
              )}
            />
          </Col>
          <Col md={4}>
            <StyledLabel for="medicalNumber">Medicaid Number</StyledLabel>
            <Controller
              name="medicalNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInput
                  type="text"
                  id="medicalNumber"
                  {...field}
                />
              )}
            />
          </Col>
          {/* Empty Col for spacing */}
          <Col md={4}></Col>
        </Row>

        {/* Contact Information */}
        <Row form>
          <Col md={4}>
            <StyledLabel for="phoneNumber">Phone Number</StyledLabel>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{ required: 'PhoneNumber is required' }}
              render={({ field }) => (
                <StyledInput
                  type="text"
                  id="phoneNumber"
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <StyledLabel for="email">Email</StyledLabel>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInput
                  type="text"
                  id="email"
                  {...field}
                />
              )}
            />
          </Col>
          {/* Empty Col for spacing */}
          <Col md={4}></Col>
        </Row>

        {/* Appointment Details */}
        <Row form>
          <Col md={4}>
            <StyledLabel for="appointmentDate">Appointment Date</StyledLabel>
            <Controller
              name="appointmentDate"
              control={control}
              defaultValue=""
              rules={{ required: 'Appointment Date is required' }}
              render={({ field }) => (
                <StyledInput
                  type="date"
                  id="appointmentDate"
                  min={tomorrow.toISOString().split("T")[0]}
                  {...field}
                />
              )}
            />
          </Col>
          <Col md={4}>
            <StyledLabel for="appointmentTime">Appointment Time</StyledLabel>
            <Controller
              name="appointmentTime"
              control={control}
              defaultValue=""
              rules={{ required: 'Appointment time is required' }}
              render={({ field }) => (
                <StyledInput
                  type="time"
                  id="appointmentTime"
                  {...field}
                />
              )}
            />
          </Col>
          {/* Empty Col for spacing */}
          <Col md={4}></Col>
        </Row>

        {/* Pickup Address */}
        <Row form>
          <Col md={4}>
            <StyledLabel for="pickupLocation">Pickup Location</StyledLabel>
            <Controller
              name="pickupLocation"
              control={control}
              defaultValue=""
              rules={{ required: 'Pickup Location is required' }}
              render={({ field }) => (
                <>
                  <StyledInput
                    {...field}
                    type="text"
                    id="pickupBox"
                    placeholder="Enter pickup location"
                    invalid={!!errors.pickupLocation}
                  />
                  <div id="pickupBoxContainer"></div>
                  <ErrorText>{errors.pickupLocation?.message}</ErrorText>
                </>
              )}
            />
          </Col>
          <Marginer margin="10px" />
          <Col md={4}>
            <StyledLabel for="dropoffLocation">Dropoff Location</StyledLabel>
            <Controller
              name="dropoffLocation"
              control={control}
              defaultValue=""
              rules={{ required: 'Dropoff Location is required' }}
              render={({ field }) => (
                <>
                  <StyledInput
                    {...field}
                    type="text"
                    id="dropoffBox"
                    placeholder="Enter dropoff location"
                    invalid={!!errors.dropoffLocation}
                  />
                  <div id="dropoffBoxContainer"></div>
                  <ErrorText>{errors.dropoffLocation?.message}</ErrorText>
                </>
              )}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <StyledLabel for="rideType">Ride Type</StyledLabel>
            <FormGroup>
              <Controller
                name="rideType"
                control={control}
                defaultValue="1" // Assuming "0" is a valid default value and it's set as a string
                render={({ field }) => (
                  <>
                    <div>
                      <StyledInput type="radio" {...field} id="rideTypeWheelchair" value="0" checked={field.value === "0"} />
                      <StyledLabel for="rideTypeWheelchair">Wheelchair</StyledLabel>
                    </div>
                    <div>
                      <StyledInput type="radio" {...field} id="rideTypeAmbulatory" value="1" checked={field.value === "1"} />
                      <StyledLabel for="rideTypeAmbulatory">Ambulatory</StyledLabel>
                    </div>
                  </>
                )}
              />
            </FormGroup>
          </Col>

          {/* Round Trip Checkbox */}
          <Col md={6}>
            <StyledLabel for="roundTrip">Round Trip</StyledLabel>
            <Controller
              name="isRoundTrip"
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <FormGroup>
                  <StyledInput type="checkbox" id="roundTrip" {...field} checked={field.value} />
                  <StyledLabel for="roundTrip">Check for Round Trip</StyledLabel>
                </FormGroup>
              )}
            />
          </Col>
        </Row>


        {/* Drop-off Address */}

        {/* Special Notes */}
        <Row form>
          <Col md={12}>
            <StyledLabel for="specialNotes">Special Notes</StyledLabel>
            <Controller
              name="specialNotes"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <StyledInput
                  type="textarea"
                  id="specialNotes"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>

        {/* Submit Button */}
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <Button className='mt-3' style={{ backgroundColor: "red", borderColor: "red" }} type="submit">
            Book Ride
          </Button>
        )}
        <p style={{ marginTop: '10px', fontSize: '12px', fontWeight: 'bold', color: 'black' }}>
          DISCLAIMER<br />
          If you are being referred to receive care outside of your County,
          “Beyond 25 miles” your medical provider has to send us a letter stating that ‘’why
          you cannot receive care closer to your homes’’The letter needs to be written by the physician
          that is referring you to seek medical care outside of the county.
        </p>
      </StyledForm>
    </StyledContainer>
  );
};






import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { constants } from "../../common";
import axios from "axios";
import useFormDataStore from "../../store/store";


export const PaymentStatus = ({ isSuccess }) => {
  const [redirectTimer, setRedirectTimer] = useState(10);
  const [paymentStatus, setPaymentStatus] = useState(null); // Added state for payment status
  const navigate = useNavigate();
  const sessionId = localStorage.getItem("ssId");
  const { formData } = useFormDataStore();


  const handleSaveRide = async () => {
    try {
      const authToken = localStorage.getItem('token');
      const headers = authToken ? { Authorization: `Bearer ${authToken}` } : {};
  
      const response = await axios.post(`${constants.baseUrl}rides`, formData, { headers });
  
      if (response.status === 201) {
        // Handle success as needed
      } else {
        console.error('Unexpected response status:', response.status);
        // Handle error
      }
    } catch (error) {
      console.error('Error saving ride:', error);
      // Handle error
    }
  };


  
  useEffect(() => {
    const fetchPaymentStatus = async () => {
      
  
      try {
        const response = await axios.get(`${constants.baseUrl}api/payment/status/${sessionId}`);
        
        if (response.status === 200) {
          const data = response.data;
          setPaymentStatus(data);
          if(data==='Payment succeeded!'){
            handleSaveRide();
            localStorage.clear("ssId");
          }
          
 

        } else {
          console.error("Error fetching payment status. Unexpected response status:", response.status);
        }
  
      } catch (error) {
        console.error("Error fetching payment status:", error);
      }
    };
  
    fetchPaymentStatus();
  }, []);




  
    


 

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      {paymentStatus !== null ? (
        <>
          <h1 style={{ color: paymentStatus === "Payment succeeded!" ? "green" : "red" }}>
            {paymentStatus}
          </h1>
          
        </>
      ) : (
        <p>Loading payment status...</p>
      )}
    </div>
  );
};


import React from "react";
import { Navbar } from "../components/navbar";
import styled from "styled-components";
import { Footer } from '../components/footer';
import { PastRideTable } from "../components/pastRidesTable";



const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: min-content;
  background-color: black;
  color: #000; /* Black text color */
`;


export const PastRidesPage=()=>{

    return(
        <Container>
            <Navbar/>
            <PastRideTable/>
           <Footer/>
        </Container>

    );
}
import React, { useState, useEffect } from 'react';
import { Table, Spinner } from 'reactstrap';
import axios from 'axios';
import styled from 'styled-components';
import { constants } from '../../common';

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  min-height:50em;
`;

const TableHead = styled.thead`
  background-color: #3498db;
  color: #fff;
`;

const TableBody = styled.tbody`
  background-color: #ecf0f1;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #bdc3c7;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

export const PastRideTable = () => {
  const [rides, setRides] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = `${constants.baseUrl}customers/ride`;
  const token = localStorage.getItem('token');

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRides(response.data);
        } else {
          throw new Error('Failed to fetch rides');
        }
      })
      .catch((error) => {
        console.error('Error fetching ride data:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Spinner color="primary" />;
  }

  if (!rides || rides.length === 0) {
    return <p>No past rides available.</p>;
  }

  return (
    <StyledTable striped bordered hover>
      <TableHead>
        <tr>
          <th>ID</th>
          <th>Pickup Location</th>
          <th>Drop-off Location</th>
          <th>Distance</th>
          <th>Fare</th>
          <th>Date</th>
        </tr>
      </TableHead>
      <TableBody>
        {rides.map((ride) => (
          <TableRow key={ride.id}>
            <TableCell>{ride.id}</TableCell>
            <TableCell>{ride.pickupLocation}</TableCell>
            <TableCell>{ride.dropoffLocation}</TableCell>
            <TableCell>{ride.distance.toFixed(2)}</TableCell>
            <TableCell>{ride.fareAmount.toFixed(2)}</TableCell>
            <TableCell>{new Date(ride.appointmentDate).toLocaleString().substring(0, 10)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </StyledTable>
  );
};
import {create} from 'zustand';
import { persist } from 'zustand/middleware';

const useFormDataStore = create(
  persist(
    (set) => ({
      formData: {
        firstName: '',
        lastName: '',
        pickupLocation: '',
        dropoffLocation: '',
        phone: '',
        appointmentDate: '',
        appointmentTime: '',
        rideType:'',
        roundTrip:'',
        specificRequirements: '',
      },
      setFormData: (data) => set((state) => ({ formData: { ...state.formData, ...data } })),
    }),
    {
      name: 'formDataStore', // Unique name for the local storage entry
      getStorage: () => localStorage, // Use local storage
    }
  )
);

export default useFormDataStore;

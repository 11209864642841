import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

const cardData = [
  {
    headline: 'Our Values',
    subheadline: 'We don’t believe in a quantity we believe in quality because you matter to us',
    backgroundColor: '#d90700', // Dark Red
  },
  {
    headline: 'What is Private Pay',
    subheadline: 'Private Pay is a personalized approach to well-being, placing individuals at the forefront of their health journey by providing a direct, self-funded option for transportation services.',
    backgroundColor: '#000', // Black
  },

  
  {
    headline: 'Our Goal',
    subheadline: 'We remove transportation barriers to ensure everyone gets the care they need.',
    backgroundColor: '#d90700', // Dark Red
  },
];

const Card = styled.div`
  flex: 1;
  padding: 1.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  text-align: center;
  height:15rem;
`;
const CardHeading = styled.h2`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const CardSubheading = styled.p`
  margin-top: 0.5rem;
  font-size: 1.125rem;
`;

export const PastWork = () => {
  const cardComponents = cardData.map((card, index) => (
    <Card key={index} style={{ backgroundColor: card.backgroundColor }} className="text-white">
      <div>
        <CardHeading>{card.headline}</CardHeading>
        <CardSubheading>{card.subheadline}</CardSubheading>
      </div>
    </Card>
  ));

  return (
    <section className="py-16">
      <Container>
        <Row>
          <Col xs="12" sm="6" md="4">
            {cardComponents[0]}
          </Col>
          <Col xs="12" sm="6" md="4">
            {cardComponents[1]}
          </Col>
          <Col xs="12" md="4">
            {cardComponents[2]}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

import React from 'react';
import { Container, Card, CardBody } from 'reactstrap';
import styled from 'styled-components';


const FAQContainer = styled(Container)`
  margin-top: 50px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
`;

export const FAQ = () => {
  return (
    <FAQContainer>
      <h1>Frequently Asked Questions</h1>

      <StyledCard>
        <CardBody>
          <h3>Q: What are the hours of operation?</h3>
          <p>A: Our office and scheduling hours are Monday – Friday 6:00am – 7:00pm. Transportation hours are 24/7.</p>
        </CardBody>
      </StyledCard>

      <StyledCard>
        <CardBody>
          <h3>Q: Is there a limit on NEMT?</h3>
          <p>A: There is no limit to the number of daily trips an eligible member can receive from us.</p>
        </CardBody>
      </StyledCard>

      <StyledCard>
        <CardBody>
          <h3>Q: Where can a member be transported using NEMT?</h3>
          <p>A: NEMT can only be provided to services covered by Health First Colorado. NEMT is not available for picking up prescriptions, picking up medical equipment, completing interviews, or paperwork.</p>
        </CardBody>
      </StyledCard>
    </FAQContainer>
  );
};
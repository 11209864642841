import React from "react";
import styled from 'styled-components';
import titleBG from '../../assets/titleBG.jpg';

const StyledSection = styled.section`
  width: 100%;
  padding: 12rem 0;
  background-color: #000;
  color: #fff;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    flex-direction: column; // Change the order to column-reverse on mobile
  }
`;

const StyledBackgroundImage = styled.div`
  height: 23rem;
  width: 65%;
  background-size: cover;
  background-position: center;
  border-radius: 0.375rem;
  margin-right: 2rem;
  background-image: url(${titleBG});

  @media (max-width: 767px) {
    width: 100%;
    margin-right: 0; // Remove margin on mobile
    margin-bottom: 1rem; // Add some bottom margin to separate it from the text
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  h2 {
    font-size: 1.875rem;
    font-weight: bold;
    letter-spacing: -0.02em;
    line-height: 1.2;
  }

  p {
    max-width: 600px;
    font-size: 1rem;
    line-height: 1.75;
  }
`;

export const AboutUsSection = () => {
  return (
    <StyledSection>
      <StyledContainer>
       
        <StyledContent>
          <div>
            <h2>What Is Non-Emergency Medical Transportation?</h2>
          </div>
          <div>
            <p>
              Non-Emergent Medical Transportation (NEMT)
              is transportation to and from covered non-emergency medical appointments or services,
              NEMT is only available when a Health First Colorado (Colorado’s Medicaid Program) member
              has no other means of transportation.
            </p>
          </div>
        </StyledContent>
        <StyledBackgroundImage />
      </StyledContainer>
    </StyledSection>
  );
};

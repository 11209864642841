import React from 'react';
import styled from 'styled-components';
import { Navbar } from '../components/navbar';
import { Footer } from '../components/footer';
import { BookingForm } from '../components/bookingForm';
import { TitleBar } from '../components/titleBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
`;



export const BookingPage = () => {
  return (
    <Container>
      <Navbar />
      <TitleBar title={"Schedule a ride"} />
      <BookingForm />
      <Footer />
    </Container>
  );
};
import React from 'react';
import { Input, Button, FormGroup, Label, FormFeedback, Modal,Spinner, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { constants } from '../../common';
import axios from 'axios';


const StyledModal = styled(Modal)`
  & .modal-content {
    background-color: #fff;
    border-radius: 10px;
  }
`;

const FormContainer = styled.form`
  max-width: 400px;
  margin: auto;
`;

const StyledInput = styled(Input)`
  margin-bottom: 15px;
`;

const ErrorText = styled(FormFeedback)`
  display: block;
`;


export const ContactSection = ({ isOpen, onClose }) => {
  const { control, handleSubmit, formState: { errors }, watch, reset } = useForm();
  const { baseUrl } = constants;
  const [responseMessage, setResponseMessage] = React.useState(null);
  const [loading,setLoading]=React.useState(false);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axios.post(`${constants.baseUrl}api/contact/submit`, data);

      // Check if the submission was successful
      if (response.status === 200) {
        setLoading(false);
        // Handle success
        setResponseMessage('Form submitted successfully!');
        onClose(); // Close the modal after submission
        reset(); // Reset the form after successful submission
      } else {
        // Handle unexpected response status
        console.error('Unexpected response status:', response.status);
        setLoading(false);
      }
    } catch (error) {
      setResponseMessage('Error submitting contact form');
      setLoading(false);
    }
  };


  return (
    <StyledModal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Contact Us</ModalHeader>
      <ModalBody>
        {responseMessage && <p>{responseMessage}</p>}
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="name">Name</Label>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{
                required: 'Name is required'
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="text"
                    id="name"
                    {...field}
                    invalid={!!errors.name}

                  />
                  <ErrorText>{errors?.name?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="email">Email</Label>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: 'Invalid email address',
                },
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="email"
                    id="email"
                    {...field}
                    invalid={!!errors?.email}
                  />
                  <ErrorText>{errors?.email?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          <FormGroup>
            <Label for="message">Message</Label>
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{
                required: 'Message is required'
              }}
              render={({ field }) => (
                <>
                  <StyledInput
                    type="textarea"
                    id="message"
                    {...field}
                    invalid={!!errors.message}

                  />
                  <ErrorText>{errors?.message?.message}</ErrorText>
                </>
              )}
            />
          </FormGroup>

          {loading ? (
                        <Spinner color="primary" />
                    ) : (
                        <Button className='mt-3' style={{ backgroundColor: "red", borderColor: "red" }} type="submit">
                            Send
                        </Button>
                    )}
        </FormContainer>
      </ModalBody>
    </StyledModal>
  );
};
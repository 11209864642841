import React from 'react';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { HomePage } from './pages/HomePage';
import { BookingPage } from './pages/BookingPage';
import { PrivatePayPage } from './pages/PrivatePayPage';
import { AboutUsPage } from './pages/AboutUsPage';
import { PaymentStatusPage } from './pages/PaymentStatusPage';
import { FaqPage } from './pages/FaqPage';
import { PastRidesPage } from './pages/PastRidesPage';



const App = () => {
  return (
    <>
      <Router>
       
          <Routes>
            <Route path='/' exact={true} element={<HomePage />} />
            <Route path='/booking' exact={true} element={<BookingPage />} />
            <Route path='/privatePay' exact={true} element={<PrivatePayPage />} />
            <Route path='/aboutUs' exact={true} element={<AboutUsPage />} />
            <Route path='/status' exact={true} element={<PaymentStatusPage />} />
            <Route path='/FAQ' exact={true} element={<FaqPage />} />
            <Route path='/pastRides' exact={true} element={<PastRidesPage/>} />
          </Routes>
        
      </Router>
    </>
  );
};

export default App;

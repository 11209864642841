import React from "react";
import { Navbar } from "../components/navbar";
import styled from "styled-components";
import { Footer } from '../components/footer';
import { FAQ } from "../components/faq";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: min-content;
  background-color: black;
  color: #000; /* Black text color */
`;


export const FaqPage=()=>{

    React.useEffect(() => {
        // Scroll to the top on component mount
        window.scrollTo(0, 0);
      }, []);

    return(
        <Container>
            <Navbar/>
            <FAQ/>
           <Footer/>
        </Container>

    );
}
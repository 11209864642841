import React from "react";
import { Navbar } from "../components/navbar";
import styled from "styled-components";
import { AboutUsSection } from "../components/aboutussection";
import { BigAboutUs } from "../components/bigAboutUs";
import { Footer } from '../components/footer';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: min-content;
  background-color: black;
  color: #000; /* Black text color */
`;


export const AboutUsPage=()=>{

    return(
        <Container>
            <Navbar/>
            <BigAboutUs/>
           <Footer/>
        </Container>

    );
}
import React from 'react';
import styled from 'styled-components';
import titleBG from '../../assets/titleBG.jpg';
const TitleBarContainer = styled.div`
  position: relative;
  height: 200px; /* Adjust the height as needed */
  background-image: url(${titleBG});
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const TitleText = styled.h1`
  font-size: 4rem;
  font-weight: bold;
`;

export const TitleBar = ({ title }) => {
  return (
    <TitleBarContainer>
      <TitleText>{title}</TitleText>
    </TitleBarContainer>
  );
};



import React from 'react';
import styled from 'styled-components';
import {Navbar} from '../components/navbar';
import { Footer } from '../components/footer';
import { BookingForm } from '../components/bookingForm';
import { TitleBar } from '../components/titleBar';
import { RideForm } from '../components/rideForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  min-height: 100vh;
  background-color: whitesmoke;
  color: #000; /* Black text color */
  overflow-x: hidden; /* Prevent horizontal overflow */
`;


export const PrivatePayPage = () => {
    return (
      <Container>
        <Navbar />
        <TitleBar title={"Book a Private Pay Ride"}/>
        <RideForm/>
        <Footer/>
      </Container>
    );
  };
import React from "react";
import styled from 'styled-components';
import titleBG from '../../assets/bgabtus.jpg';

const StyledSection = styled.section`
  width: 100%;
  padding: 12rem 0;
  background-color: #000;
  color: #fff;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1.5rem;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-items:center;
    align-self:center;
  }
`;

const StyledBackgroundImage = styled.div`
  height: 28rem;
  width: 50%;
  background-size: cover;
  background-position: center;
  border-radius: 0.375rem;
  margin-right: 2rem;
  background-image: url(${titleBG});

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-right: 0;
  }
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  h2 {
    font-size: 1.875rem;
    font-weight: bold;
    letter-spacing: -0.02em;
    line-height: 1.2;
  }

  p {
    max-width: 600px;
    font-size: 1rem;
    line-height: 1.75;
  }
`;

export const BigAboutUs = () => {
    return (
        <StyledSection>
            <StyledContainer>
                <StyledBackgroundImage />
                <StyledContent>
                    <div>
                        <h2>About Us</h2>
                    </div>
                    <div>
                        <p>
                            The company is founded by a ride share driver that has 
                            more than six years experience in providing the best quality 
                            care for customers. His passion along with the drivers is to make 
                            medical transportation easy, fun with quality care to where you need to 
                            be on time.
                        </p>
                    </div>
                </StyledContent>
            </StyledContainer>
        </StyledSection>
    );
};

import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { SignUpModal } from '../signupModal';
import { SignInModal } from '../signInModal';
import logoImg from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { ContactSection } from '../contactUsSection';


const HeaderContainer = styled.div`
  background-color: black;
  color: #333;
  height: 2rem;
  text-align: center;
`;

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: whitesmoke;
  color: black;
`;

const moveSideways = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const HeaderText = styled.p`
  font-size: 1.3rem;
  margin: 0;
  font-weight: bold;
  color: whitesmoke;

  @media (max-width: 767px) {
    animation: ${moveSideways} 10s linear infinite;
    white-space: nowrap;
  }
`;
const Logo = styled.img`
  width: 150px;
  height: 80px;
 
`;

const MenuIcon = styled.div`
  display: none;
  font-size:30px;

  @media (max-width: 768px) {
    display: block;
    cursor: pointer;
    color: red;
  }
`;

const NavbarLinks = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: white;
    text-align: center;
    display: ${(props) => (props.open ? 'flex' : 'none')};
    z-index: 1;
  }
`;

const NavbarLink = styled.a`
  margin: 0 15px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

// ... (styles for SocialIcon)

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const hasToken = localStorage.getItem('token');
  const navigate = useNavigate();

  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const openSignUpModal = () => {
    setIsSignUpModalOpen(true);
  };

  const closeSignUpModal = () => {
    setIsSignUpModalOpen(false);
  };

  
  const openContactModal = () => {
    setIsContactModalOpen(true);
  };

  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };




  const openSignInModal = () => {
    setIsSignInModalOpen(true);
  };

  const closeSignInModal = () => {
    setIsSignInModalOpen(false);
  };
  
  
    const phoneNumber = "719-785-1717";
  
    const handlePhoneClick = () => {
      window.location.href = `tel:${phoneNumber}`;
    };
  



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <HeaderContainer>
        <HeaderText>
          <FontAwesomeIcon icon={faPhone} onClick={handlePhoneClick} /> Contact us: <a href="#" style={{color:"whitesmoke",textDecoration:"none"}} onClick={handlePhoneClick}>
                  {phoneNumber}
                </a> | Call to
          schedule: Mon-Fri 6AM-7PM| RIDES AVAILABLE 24/7
        </HeaderText>
      </HeaderContainer>
      <NavbarContainer ref={navbarRef}>
        <Logo src={logoImg} onClick={()=>navigate('/')} alt="Light Ride Logo" />
        <MenuIcon onClick={toggleMenu}>
          <span>&#9776;</span>
        </MenuIcon>
        <NavbarLinks open={menuOpen}>
          <NavbarLink onClick={()=>navigate('/')} >Home</NavbarLink>
          <NavbarLink onClick={()=>navigate('/privatePay')} >Book Using Private Pay</NavbarLink>
          <NavbarLink onClick={()=>navigate('/aboutUs')} >About us</NavbarLink>

          <NavbarLink onClick={openSignUpModal}>Sign up</NavbarLink>
          <NavbarLink onClick={openSignInModal}>Sign In</NavbarLink>
          {hasToken && (
          <NavbarLink onClick={()=>navigate('/pastRides')}>Show Previous Trips</NavbarLink>
        )}
          <NavbarLink onClick={openContactModal}>Contact us</NavbarLink>
        </NavbarLinks>
        <SignUpModal isOpen={isSignUpModalOpen} header={"Sign uo"} onClose={closeSignUpModal} />
        <SignInModal isOpen={isSignInModalOpen} onClose={closeSignInModal} />
        <ContactSection isOpen={isContactModalOpen} onClose={closeContactModal} />
      </NavbarContainer>
    </>
  );
};
import React from "react";
import styled from 'styled-components';
import { NavLink } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebook, faInstagram, faTwitter, faXTwitter, faYelp } from '@fortawesome/free-brands-svg-icons';
import { faX } from "@fortawesome/free-solid-svg-icons";
import privacyPolicy from "../../assets/privacyPolicy.pdf";
import termsService from "../../assets/TermsofService.pdf";



const StyledFooter = styled.footer`
  background-color: #000;
  color: #fff;
  padding: 3rem 2rem;
  position: relative;
  margin-top: auto; /* Push the footer to the bottom of its containing element */
`;

const StyledContainer = styled.div`
  margin: auto;
  max-width: 90rem;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  gap: 2rem;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  margin-top: 10px;

  a {
    margin-right: 10px;
  }
`;

const StyledSection = styled.div`
  h3 {
    font-size: 1.125rem;
    font-weight: bold; /* Added to make the title bold */
    margin-bottom: 0.75rem;
  }

  p, ul {
    font-size: 0.875rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #69a3f0;
    }
  }
`;

const StyledBottomSection = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  border-top: 1px solid #444;
  padding-top: 1.5rem;
`;

export function Footer() {

  const navigate = useNavigate();


  const phoneNumber = "719-785-1717";

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <StyledFooter>
      <StyledContainer>
        <StyledSection>
          <h3>Office Hours</h3>
          6:00 am to 6:00pm Weekdays<br />
          24/7 transportation service
           <div style={{margin:"15px"}}/>
          <a href="https://nationalcprfoundation.com/" class="ncpr-button">
            <img src="https://nationalcprfoundation.com/wp-content/themes/vantage/new/images/badge.png" alt="We Are Certified With NationalCPRFoundation"/></a><script type="text/javascript" src="https://nationalcprfoundation.com/badge-script.js?cid=15283"></script>
        </StyledSection>
        <StyledSection>
          <h3>Services</h3>
          <ul>
            <li>
              <NavLink href="#" className="text-sm hover:underline">Medical Appointments</NavLink>
            </li>
            <li>
              <NavLink href="#" className="text-sm hover:underline">Hospital Discharge</NavLink>
            </li>
            <li>
              <NavLink href="#" className="text-sm hover:underline">Dialysis Treatment</NavLink>
            </li>
            <li>
              <NavLink href="#" className="text-sm hover:underline">Physical Therapy</NavLink>
            </li>
          </ul>
        </StyledSection>
        <StyledSection>
          <h3>Resources</h3>
          <ul>
            <li>
              <NavLink onClick={() => navigate('/FAQ')} className="text-sm hover:underline">FAQs</NavLink>
            </li>
            <li>
            <a href={termsService} target="_blank" rel="noopener noreferrer" className="text-sm hover:underline">
                Terms and Conditions
              </a>
            </li>
            <li>
              <a href={privacyPolicy} target="_blank" rel="noopener noreferrer" className="text-sm hover:underline">
                Privacy Policy
              </a>
            </li>
          </ul>
        </StyledSection>
        <StyledSection>
          <h1>Contact Us</h1>
          <ul>
            <li>
              <h2>
                <a href="#" className="text-lg hover:underline" onClick={handlePhoneClick}>
                  {phoneNumber}
                </a>
              </h2>
            </li>
            <li>
              <h3 href="#" className="text-lg hover:underline">support@ridewithlight.com</h3>
            </li>
            <SocialIconsContainer>
              <a href="https://maps.app.goo.gl/rP5VH73H5MWbWKgs9?g_st=iw" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGoogle} size="2x" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=61555615173069&mibextid=kFxxJD" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
              <a href="https://www.instagram.com/ride_with_light?igsh=dXB3ajF4bTJ5amRk" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://x.com/light_ride1110?s=11&t=89WaXuhHfUHgz_XPL0hT7A" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faXTwitter} size="2x" />
              </a>
              <a href="https://yelp.to/jwD2_KF11T" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYelp} size="2x" />
              </a>
            </SocialIconsContainer>
          </ul>
        </StyledSection>
      </StyledContainer>
      <StyledBottomSection>
        <p className="text-sm">© 2023 Light Ride llc. All Rights Reserved.</p>
        <p className="text-sm">powered by nihilo</p>
      </StyledBottomSection>
    </StyledFooter>
  );
}

